<template>
    <span class="billing-status" :class="status.toLowerCase()">
        {{parseStatus(status)}}
    </span>
</template>

<script>
    export default {
        name: "BillingStatus",
        props: {
            status: {
                type: String,
                required: true
            }
        },
        methods: {
            parseStatus(status){
                switch (status) {
                    case 'PASS':
                        return 'Оплачено';

                    case 'PENDING':
                        return 'В ожидании';

                    case 'FAIL':
                        return 'не оплачено';

                    default:
                        return '-'
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .billing-status{
        padding: 4px 8px;
        border-radius: 2px;
        font-size: 12px;
        color: #fff;
        text-transform: uppercase;
        background: #f15959;
        white-space: nowrap;
        &.pass{
            background: #5cb82c;
        }
        &.pending{
            background: #e4c03f;
        }
    }
</style>