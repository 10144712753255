<template>
    <div class="dashboard">
        <div class="wrapper">
            <div class="page-title">
                <div class="h1">Список транзакций</div>
                <div class="filter-box">
                    <div class="row">
                        <div class="col-xl-6 mt-1 mb-1">
                            <v-select placeholder="Выберите событие" :options="forums" v-model="selectForum" label="title">
                                <template #no-options="{ search, searching, loading }">
                                    Извините, нет доступных событий
                                </template>

                                <template #option="{ imagePath, title }">
                                    <div class="forum-td">
                                        <img v-if="imagePath" :src="imagePath" alt="">
                                        <div class="forum-td__title">
                                            {{ title }}
                                        </div>
                                    </div>
                                </template>
                            </v-select>
                        </div>
                        <div class="col-xl-6 mt-1 mb-1">
                            <user-search :isRequire="false" v-model="selectUser"/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="auto-scroll-horizontal">
                <b-table
                    :busy="isBusy"
                    :filter="{selectUser, selectForum}"
                    :fields="fields"
                    :items="billingProvider"
                    class="mb-0"
                    :current-page="currentPage"
                    :per-page="perPage"
                    empty-text="Нет доступных транзакций"
                    show-empty
                >
                    <template #cell(user)="data">
                        <div v-if="data.item.user" class="user-td">
                            <img v-if="data.item.user.avatarPath" :src="data.item.user.avatarPath" alt="">
                            <div class="user-name">
                                {{data.item.user.lastName}}
                                {{data.item.user.firstName}}
                                {{data.item.user.patroName}}
                            </div>
                        </div>
                        <strong v-else>-</strong>
                    </template>

                    <template #cell(email)="data">
                        <div v-if="data.item.user">
                            {{data.item.user.email.address.substr(0, 27)}}{{ data.item.user.email.address.length > 27 ? '...' : ''}}
                        </div>
                        <strong v-else>-</strong>
                    </template>

                    <template #cell(forum)="data">
                        <div v-if="data.item.forum">
                            {{ data.item.forum.title }}
                        </div>
                        <strong v-else>-</strong>
                    </template>

                    <template #cell(date)="data">
                        {{parseDate(data.item.createdAt)}}
                    </template>

                    <template #cell(type)="data">
                        {{ parseType(data.item.type, data.item.priceTitle) }}
                    </template>

                    <template #cell(status)="data">
                        <billing-status :status="data.item.status"/>
                    </template>

                    <template #cell(amount)="data">
                        {{ data.item.amount }} грн.
                    </template>

                    <template #table-busy>
                        <div class="text-center my-2">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                    </template>

                    <template #empty="scope">
                        <div class="text-center my-2">
                            {{ scope.emptyText }}
                        </div>
                    </template>

                    <template #emptyfiltered="scope">
                        <div class="text-center my-2">
                            {{ scope.emptyText }}
                        </div>
                    </template>
                </b-table>
            </div>
        </div>

        <div v-if="totalBillings > perPage" class="pt-3">
            <b-pagination first-number last-number :total-rows="totalBillings" :per-page="perPage" v-model="currentPage"/>
        </div>
    </div>
</template>

<script>
    import { mapActions } from 'vuex'
    import parseDate from '@/mixins/parseDate'
    import BillingStatus from "../components/billing/BillingStatus";
    import UserSearch from "../components/forms/UserSearch";

    export default {
        name: "billings",
        components: {
            UserSearch,
            BillingStatus
        },
        mixins: [parseDate],
        computed: {
            getRequestForums(){
                const data = {
                    byLevelup: true,
                    perPage: 100500,
                    draft: false
                };
                this.getForumsList(data).then(forums => {
                    this.forums = forums.results;
                })
            },
        },
        methods: {
            ...mapActions([
                'getBillings',
                'getUserForum',
                'getForumsList'
            ]),
            parseType(type, title){
                switch (type) {
                    case 'TICKET':
                        return `Билет (${title})`;

                    case 'PREMIUM':
                        return 'PREMIUM статус';

                    default:
                        return '-'
                }
            },
            billingProvider(ctx){
                const data = {
                    page: ctx.currentPage,
                };

                if(ctx.filter.selectUser){
                    data.userId = ctx.filter.selectUser._id;
                    data.page = 1;
                }

                if(ctx.filter.selectForum){
                    data.forumId = ctx.filter.selectForum._id;
                    data.page = 1;
                }

                return this.getBillings(data).then(billings => {
                    this.totalBillings = billings.totalCount;
                    this.billings = billings.results;
                    return this.billings;
                });
            }
        },
        mounted(){
            this.getRequestForums;
        },
        data(){
            return{
                isBusy: false,
                selectUser: null,
                forums: [],
                forumsOption: [],
                selectForum: null,
                billings: [],
                fields: [
                    {
                        key: 'user',
                        label: 'ФИО',
                        thStyle: {
                            width: '20%'
                        },
                    }, {
                        key: 'email',
                        label: 'E-mail'
                    }, {
                        key: 'forum',
                        label: 'Событие'
                    }, {
                        key: 'date',
                        label: 'Дата'
                    }, {
                        key: 'type',
                        label: 'Тип продукта'
                    }, {
                        key: 'status',
                        label: 'Статус'
                    }, {
                        key: 'amount',
                        label: 'Сумма'
                    }, {
                        key: 'description',
                        label: 'Описание'
                    }
                ],
                currentPage: 1,
                perPage: 10,
                totalBillings: 0,
            }
        }
    }
</script>

<style lang="scss" scoped>
    .page-title{
        display: flex;
        .h1{
            margin: 0;
        }
    }
    .filter-box{
        margin-left: auto;
        width: 740px;
    }
    .search-box{
        position: relative;
        .search-control{
            color: #013274;
            padding-right: 40px;
            border-color: #013274;
            &::placeholder{
                color: #013274;
            }
        }
        .bi{
            position: absolute;
            top:50%;
            transform: translateY(-50%);
            right: 10px;
            color: #013274;
        }
    }
    .user{
        &-td {
            display: flex;
            align-items: center;
            min-height: 48px;
            img {
                width: 48px;
                height: 48px;
                margin-right: 10px;
                border-radius: 50%;
                overflow: hidden;
                object-fit: cover;
                flex: none;
            }
        }
    }
    @media screen and (max-width: 991px) {
        .page-title{
            flex-direction: column;
            .h1{
                margin-bottom: 10px;
            }
        }
        .filter-box{
            width: auto;
            margin: 0;
            max-width: 450px;
        }
    }
</style>